/* Filter panel content container */
.filter-content {
  flex: 1;
  overflow-y: auto;
}

.filter-content > .filter-group:first-child {
  margin-top: 20px; /* Adjust this value as needed */
}

/* Filter group styling */
.filter-group {
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
  padding-bottom: 15px;
}

.filter-group:last-child {
  border-bottom: none;
}

.filter-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #eee;
}

.filter-group select {
  width: 100%;
  padding: 10px;
  background: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.filter-group select:hover {
  border-color: #1976d2;
}

.filter-group select:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

/* Layer toggle switches */
.MuiSwitch-root {
  margin: 5px 0 !important;
}

.MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: block !important;
}

/* Style for the MUI slider */
.MuiSlider-root {
  color: #1976d2 !important;
  margin-top: 16px !important;
}

.MuiSlider-rail {
  background-color: #666 !important;
  height: 4px !important;
}

.MuiSlider-track {
  background-color: #1976d2 !important;
  height: 4px !important;
}

.MuiSlider-thumb {
  background-color: #fff !important;
  border: 2px solid #1976d2 !important;
  width: 16px !important;
  height: 16px !important;
}

.MuiSlider-valueLabel {
  background-color: #1976d2 !important;
}

.school-focus-info {
  margin-bottom: 15px;
  padding: 8px;
  background-color: rgba(25, 118, 210, 0.1);
  border-left: 3px solid #1976d2;
  border-radius: 2px;
  font-size: 0.9em;
}

.slider-value {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .filter-group {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  
  .filter-group label {
    font-size: 16px;
  }
  
  .filter-group select {
    padding: 12px;
    font-size: 16px;
  }
  
  /* Ensure touch targets are large enough */
  .MuiSwitch-root,
  .MuiFormControlLabel-root {
    padding: 5px 0;
  }
  
  /* Increase slider size for better touch interaction */
  .MuiSlider-thumb {
    width: 20px !important;
    height: 20px !important;
  }
}