/* Map container */
.map-container {
  flex: 1;
  position: relative;
  height: 100%;
}

/* Ensure the Leaflet map fills the container */
.map-container .leaflet-container {
  height: 100%;
  width: 100%;
  background: #f8f8f8;
}

/* Style for custom icons */
.school-icon, .farm-icon {
  background: transparent;
  border: none;
}

/* Override Leaflet's default icon shadow */
.leaflet-shadow-pane {
  display: none;
}

/* Zoom controls positioning - UPDATED WITH HIGHER Z-INDEX */
.leaflet-control-zoom {
  margin: 15px !important;
  z-index: 999 !important; /* Higher than legend */
}

/* Mobile-specific zoom control positioning */
@media (max-width: 768px) {
  .leaflet-control-zoom {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    margin: 0 !important;
    z-index: 999 !important; /* Ensure higher z-index */
  }
  
  /* Add additional styling to make zoom controls more visible */
  .leaflet-control-zoom a {
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    font-size: 18px !important;
    background-color: white !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3) !important;
  }
}

/* Popup styling */
.leaflet-popup-content-wrapper {
  padding: 5px;
  border-radius: 4px;
}

.leaflet-popup-content {
  margin: 10px;
  line-height: 1.5;
}