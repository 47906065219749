/* Main container styles */
body, html, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.school-farm-app {
  height: 100%;
  font-family: Arial, sans-serif;
}

/* App container with flex layout */
.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

/* Mobile controls - updated positioning and styles */
.mobile-controls {
  display: none;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 1001;
  padding: 0 10px;
}

.back-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-toggle-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #1976d2;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Filter panel header */
.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

/* Desktop back button styling */
.desktop-back {
  background: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.desktop-back:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.back-arrow {
  font-size: 20px;
  color: white;
}

.back-text {
  color: white;
  font-size: 14px;
}

/* Hide desktop back button on mobile */
@media (max-width: 768px) {
  .desktop-back {
    display: none;
  }
}

/* Filter panel */
.filter-panel {
  width: 320px;
  padding: 20px;
  background: #222;
  color: white;
  overflow-y: auto;
  flex-shrink: 0;
  z-index: 1000;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Move reset button to bottom with flex */
.filter-reset {
  margin-top: auto;
  padding-top: 20px;
}

.reset-button {
  width: 100%;
  padding: 10px;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #b71c1c;
}

/* Close filters button (mobile only) */
.close-filters-btn {
  display: none;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

/* Make layout responsive */
@media (max-width: 768px) {
  /* Show mobile controls */
  .mobile-controls {
    display: block;
  }
  
  /* Hide the back button in the filter header on mobile */
  .filter-header {
    display: none;
  }
  
  /* Full screen filter panel that's hidden by default */
  .filter-panel {
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 2000;
  }
  
  /* Show filter panel when active */
  .filter-panel.filter-visible {
    transform: translateX(0);
  }
  
  /* Show the close button on mobile */
  .close-filters-btn {
    display: block;
  }
}

.filter-icon {
  width: 20px;
  height: 20px;
}