/* Updated Legend styling */
.map-legend {
  position: absolute;
  bottom: 20px;
  left: 20px; /* Changed from right to left */
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 998; /* Lower than zoom controls */
  max-width: 120px; /* Limit width */
}

/* Adjust legend position on mobile to avoid taking full width */
@media (max-width: 768px) {
  .map-legend {
    bottom: 20px;
    left: 20px;
    padding: 8px;
    font-size: 12px;
    width: auto;
    max-width: 100px;
  }
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-size: contain;
}

/* Make legend icons smaller on mobile */
@media (max-width: 768px) {
  .legend-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  
  .legend-item {
    margin-bottom: 3px;
  }
}